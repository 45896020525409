export const GeneralLinks = () => {
  return [
    { id: 0, name: 'landing', displayName: '랜딩', url: '/superstack/' },
    {
      id: 1,
      name: 'displaying_list',
      displayName: '포지션 찾기',
      url: '/superstack/jobs/displaying/',
    },
    {
      id: 2,
      name: 'ss_profile_mine_home',
      displayName: '프로필 관리',
      url: '/superstack/profiles/mine/home/',
    },
    {
      id: 3,
      name: '자주 묻는 질문',
      displayName: '자주 묻는 질문',
      url: 'https://wishketjobs.notion.site/FAQ-fb01ccdd9d8546c2babe4a67cc0b2b6a',
    },
    { id: 4, name: 'liked_list', displayName: '관심 포지션', url: '/superstack/jobs/liked/' },
    {
      id: 5,
      name: 'ss_profile_mine_contact_info_manage',
      displayName: '연락처 정보 설정',
      url: '/superstack/profiles/mine/contact/',
    },
    {
      id: 6,
      name: 'stacks_companies',
      displayName: '기술 스택',
      url: '/superstack/stacks/companies/',
    },
    { id: 7, name: 'jobs_mine', displayName: '지원한 포지션', url: '/superstack/apply/jobs/mine/' },
    {
      id: 8,
      name: 'company_culture_review_results',
      displayName: '컬처코드',
      url: '/superstack/cultures/companies/',
    },
    { id: 11, name: '위시켓', displayName: '위시켓', url: 'https://www.wishket.com/' },
    {
      id: 12,
      name: '관리 파일 다운로드',
      displayName: '관리 파일 다운로드',
      url: 'https://www.wishket.com/file/wishket_file/',
    },
    { id: 13, name: 'wishket_signup', displayName: '회원가입', url: '/wishket-member/signup/' },
    { id: 14, name: 'wishket_signin', displayName: '로그인', url: '/wishket-member/login/' },
    {
      id: 20,
      name: 'ss_profile_mine_form_router',
      displayName: '프로필 폼 라우터',
      url: '/superstack/profiles/mine/form-router/',
    },
    {
      id: 21,
      name: 'ss_profile_mine_skills_stacks_form',
      displayName: '보유 기술 스택 폼',
      url: '/superstack/profiles/mine/skills-stacks-form/',
    },
    {
      id: 22,
      name: 'ss_profile_mine_employment_form',
      displayName: '경력 폼',
      url: '/superstack/profiles/mine/employment-form/',
    },
    {
      id: 23,
      name: 'ss_profile_mine_introduction_form',
      displayName: '자기소개 폼',
      url: '/superstack/profiles/mine/introduction-form/',
    },
    {
      id: 31,
      name: 'ss_profile_mine_skill',
      displayName: '프로필 보유기술',
      url: '/superstack/profiles/mine/skill/',
    },
  ];
};
