export const ApiUrls = (companyId = null, jobId = null, username = null, applyId = null) => {
  return [
    { id: 1, name: 'jobs_all', url: '/superstack/jobs/all/' },
    { id: 2, name: 'job_like', url: `/superstack/jobs/${jobId}/like` },
    { id: 3, name: 'job_images', url: `/superstack/jobs/${jobId}/images` },
    { id: 4, name: 'job_detail_api', url: `/superstack/jobs/${jobId}/api` },
    { id: 5, name: 'jobs_filters', url: 'superstack/jobs/filters' },
    { id: 6, name: 'job_recruiter_copy', url: `superstack/jobs/${jobId}/recruiter/` },
    { id: 7, name: 'job_likes_all', url: '/superstack/jobs/likes/all/' },
    { id: 8, name: 'user_job_likes', url: '/superstack/jobs/likes/all/?username=' },
    { id: 9, name: 'jobs_landing', url: '/superstack/jobs/landing' },
    { id: 11, name: 'job_images_duplicate', url: `/superstack/jobs/${jobId}/images-duplicate` },
    { id: 11, name: 'job_recommendations', url: `/superstack/jobs/${jobId}/recs` },
    { id: 20, name: 'company_list', url: '/superstack/companies/' },
    {
      id: 21,
      name: 'company_job_enroll',
      url: `/superstack/companies/${companyId}/jobs/${jobId}/enroll`,
    },
    { id: 22, name: 'company_jobs', url: `/superstack/companies/${companyId}/jobs/` },
    { id: 23, name: 'company_job', url: `/superstack/companies/${companyId}/jobs/${jobId}/` },
    { id: 25, name: 'user_company', url: `/superstack/companies/find/${username}/` },
    {
      id: 26,
      name: 'company_job_apply',
      url: `/superstack/companies/${companyId}/jobs/${jobId}/applies/${applyId}/`,
    },
    {
      id: 27,
      name: 'company_admins',
      url: `/superstack/companies/${companyId}/admins/`,
    },
    {
      id: 28,
      name: 'company_job_position',
      url: `/superstack/companies/${companyId}/jobs/${jobId}/position`,
    },
    {
      id: 29,
      name: 'company_detail',
      url: `/superstack/companies/${companyId}`,
    },
    {
      id: 30,
      name: 'company_service_list',
      url: `/superstack/companies/${companyId}/services`,
    },
    {
      id: 31,
      name: 'company_culture',
      url: `/superstack/companies/${companyId}/culture`,
    },
    {
      id: 32,
      name: 'company_team_list',
      url: `/superstack/companies/${companyId}/teams`,
    },
    {
      id: 33,
      name: 'company_register',
      url: `/superstack/companies/${companyId}/register/new`,
    },
    {
      id: 34,
      name: 'company_job_submit',
      url: `/superstack/companies/${companyId}/jobs/${jobId}/submit`,
    },
    {
      id: 35,
      name: 'company_logo',
      url: `/superstack/companies/${companyId}/logo`,
    },
    {
      id: 36,
      name: 'company_recently_modified_job',
      url: `/superstack/companies/${companyId}/jobs/recently-modified`,
    },
    {
      id: 37,
      name: 'check_company_identity',
      url: '/superstack/companies/identity/',
    },
    {
      id: 38,
      name: 'company_admin_add_initiater',
      url: `/superstack/companies/${companyId}/admins/add/initiater/`,
    },
    {
      id: 39,
      name: 'company_stacks',
      url: `/superstack/companies/${companyId}/stacks/`,
    },
    {
      id: 40,
      name: 'companies_stacks',
      url: '/superstack/companies/stacks/',
    },
    {
      id: 200,
      name: 'company_stacks_page',
      url: `/superstack/companies/${companyId}/stacks-page/`,
    },
    {
      id: 201,
      name: 'company_recruiting_jobs',
      url: `/superstack/companies/${companyId}/jobs/recruiting`,
    },
    {
      id: 202,
      name: 'company_subscription',
      url: `/superstack/companies/${companyId}/subscription/`,
    },
    {
      id: 204,
      name: 'company_matching_scores',
      url: `/superstack/companies/${companyId}/scores/`,
    },
    { id: 41, name: 'job_apply_fe', url: `/superstack/apply/jobs/${jobId}/` },
    { id: 42, name: 'jobs_mine', url: '/superstack/apply/jobs/mine/' },
    { id: 43, name: 'job_applies', url: `/superstack/apply/jobs/${jobId}/applies/` },
    { id: 50, name: 'job_applies_count', url: `/superstack/apply/jobs/${jobId}/applies-count/` },
    {
      id: 44,
      name: 'job_resumes',
      url: `/superstack/apply/jobs/${jobId}/applies/${applyId}/resumes/`,
    },
    {
      id: 45,
      name: 'job_apply_interesting',
      url: `/superstack/apply/jobs/${jobId}/applies/${applyId}/interesting/`,
    },
    {
      id: 46,
      name: 'job_apply',
      url: `/superstack/apply/jobs/${jobId}/applies/${applyId}/`,
    },
    {
      id: 47,
      name: 'job_apply_comments',
      url: `/superstack/apply/jobs/${jobId}/applies/${applyId}/comments/`,
    },
    {
      id: 48,
      name: 'applies_all',
      url: '/superstack/apply/applies/all/',
    },
    {
      id: 49,
      name: 'user_applies',
      url: '/superstack/apply/applies/all/?username=',
    },
    { id: 61, name: 'ss_user_info', url: '/superstack/profiles/info/' },
    { id: 62, name: 'ss_activation', url: `/superstack/auth/${username}/activation/` },
    { id: 63, name: 'ss_profile_image', url: `/superstack/profiles/${username}/image/` },
    {
      id: 64,
      name: 'ss_profile_contact_info',
      url: `/superstack/profiles/${username}/api/contact/`,
    },
    { id: 65, name: 'ss_profiles_all', url: '/superstack/profiles/' },
    { id: 66, name: 'ss_profile', url: `/superstack/profiles/${username}/api` },
    {
      id: 67,
      name: 'skill_update',
      url: `/superstack/profiles/${username}/skill/update/`,
    },
    {
      id: 68,
      name: 'employment_update',
      url: `/superstack/profiles/${username}/employment/update/`,
    },
    {
      id: 69,
      name: 'introduction_update_edit',
      url: `/superstack/profiles/${username}/introduction/update/edit/`,
    },
    {
      id: 70,
      name: 'ss_email_submit',
      url: '/superstack/profiles/email/submission/',
    },
    {
      id: 71,
      name: 'ss_profile_viewer',
      url: `/superstack/profiles/${username}/viewer/`,
    },
    {
      id: 72,
      name: 'ss_profile_pdf_download',
      url: `/superstack/profiles/${username}/download/`,
    },
    {
      id: 73,
      name: 'ss_profile_skills',
      url: `/superstack/profiles/${username}/skills/`,
    },
    {
      id: 74,
      name: 'ss_profile_total_career',
      url: `/superstack/profiles/${username}/total_career/`,
    },
    {
      id: 75,
      name: 'education_update',
      url: `/superstack/profiles/${username}/education/update/`,
    },
    {
      id: 76,
      name: 'ss_profile_is_rookie',
      url: `/superstack/profiles/${username}/is_rookie/`,
    },
    {
      id: 77,
      name: 'ss_profile_desired_roles',
      url: `/superstack/profiles/${username}/desired_roles/`,
    },
    { id: 78, name: 'ss_default_profile', url: `/superstack/profiles/${username}/default/api` },
    {
      id: 79,
      name: 'ss_profile_matching_agree',
      url: `/superstack/profiles/${username}/matching_agree/`,
    },

    {
      id: 101,
      name: 'ss_profile_summary',
      url: `/superstack/profiles/${username}/profile-summary/`,
    },
    { id: 111, name: 'ss_all_sido_view', url: '/superstack/address/api/all_sido/' },
    { id: 112, name: 'ss_all_sigungu_view', url: '/superstack/address/api/all_sigungu/' },
    { id: 113, name: 'ss_all_address_kor', url: '/superstack/address/api/all_address_kor/' },
    { id: 301, name: 'resend_email', url: '/api/authentication/email/' },
    { id: 302, name: 'external_company_search', url: '/api/companies/_search/' },
    { id: 303, name: 'external_company_info', url: '/api/companies/_info/' },
  ];
};
